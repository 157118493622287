<template>
	<div id="g-recaptcha" />
</template>

<script>
	import { ref, onMounted } from "vue";
	export default {
		props: {
			sitekey: String,
		},
		setup(props, { emit }) {
			const widgetId = ref(null);
			const render = () => {
				if (window.grecaptcha) {
					window.grecaptcha.enterprise.ready(() => {
						widgetId.value = window.grecaptcha.enterprise.render("g-recaptcha", {
							sitekey: props.sitekey,
							tabindex: 0,
							callback: emitSuccess,
							"expired-callback": emitExpired,
							"error-callback": emitFail,
						});
					});
				}
			};

			const emitSuccess = (result) => emit("onCaptchaSuccess", result);
			const emitFail = (result) => emit("onCaptchaFail", result);
			const emitExpired = () => emit("onCaptchaExpired");

			onMounted(() => {
				render();
			});
		},
		emits: ["onCaptchaSuccess", "onCaptchaFail", "onCaptchaExpired"],
	};
</script>

<style scoped>
	#g-recaptcha {
		display: inline-block;
	}
</style>
