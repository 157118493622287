<template>
	<div class="card-form p-8">
		<div class="card-list">
			<div class="card-item" v-bind:class="{ '-active': isCardFlipped }">
				<div class="card-item__side -front">
					<div class="card-item__focus" v-bind:class="{ '-active': focusElementStyle }" v-bind:style="focusElementStyle" ref="rFocusElement"></div>
					<div class="card-item__cover">
						<img v-bind:src="'cards/' + currentCardBackground + '.jpeg'" class="card-item__bg" />
					</div>

					<div class="card-item__wrapper">
						<div class="card-item__top" style="margin-bottom: 0">
							<img src="cards/chip.png" class="card-item__chip" />
							<div class="card-item__type">
								<transition name="slide-fade-up">
									<img v-bind:src="'cards/' + getCardType + '.png'" v-if="getCardType" v-bind:key="getCardType" alt="" class="card-item__typeImg" />
								</transition>
							</div>
						</div>
						<label for="cardAlias" class="card-item__number" ref="rCardAlias" style="margin-bottom: 8px">
							<div class="card-item__holder">Alias</div>
							<transition name="slide-fade-up">
								<div class="card-item__name" v-if="cardAlias.length" key="1">
									<transition-group name="slide-fade-right">
										<span class="card-item__nameItem" v-for="(n, $index) in cardAlias.replace(/\s\s+/g, ' ')" v-bind:key="$index + 1">{{ n }}</span>
									</transition-group>
								</div>
								<div class="card-item__name" v-else key="2">Mi Tarjeta</div>
							</transition>
						</label>
						<label for="cardNumber" class="card-item__number" ref="rCardNumber" style="margin-bottom: 5px">
							<template v-if="getCardType === 'american_express'">
								<span v-for="(n, $index) in amexCardMask" :key="$index">
									<transition name="slide-fade-up">
										<div class="card-item__numberItem" v-if="$index > 4 && $index < 14 && cardNumber.length > $index && n.trim() !== ''">
											{{ cardNumber[$index] }}
										</div>
										<div
											class="card-item__numberItem"
											:class="{
												'-active': n.trim() === '',
											}"
											:key="$index"
											v-else-if="cardNumber.length > $index"
										>
											{{ cardNumber[$index] }}
										</div>
										<div
											class="card-item__numberItem"
											:class="{
												'-active': n.trim() === '',
											}"
											v-else
											:key="$index + 1"
										>
											{{ n }}
										</div>
									</transition>
								</span>
							</template>

							<template v-else>
								<span v-for="(n, $index) in otherCardMask" :key="$index">
									<transition name="slide-fade-up">
										<div class="card-item__numberItem" v-if="$index > 4 && $index < 15 && cardNumber.length > $index && n.trim() !== ''">
											{{ cardNumber[$index] }}
										</div>
										<div
											class="card-item__numberItem"
											:class="{
												'-active': n.trim() === '',
											}"
											:key="$index"
											v-else-if="cardNumber.length > $index"
										>
											{{ cardNumber[$index] }}
										</div>
										<div
											class="card-item__numberItem"
											:class="{
												'-active': n.trim() === '',
											}"
											v-else
											:key="$index + 1"
										>
											{{ n }}
										</div>
									</transition>
								</span>
							</template>
						</label>
						<div class="card-item__content">
							<label for="cardName" class="card-item__info" ref="rCardName">
								<div class="card-item__holder">Tarjetahabiente</div>
								<transition name="slide-fade-up">
									<div class="card-item__name" v-if="cardName.length" key="1">
										<transition-group name="slide-fade-right">
											<span class="card-item__nameItem" v-for="(n, $index) in cardName.replace(/\s\s+/g, ' ')" v-bind:key="$index + 1">{{ n }}</span>
										</transition-group>
									</div>
									<div class="card-item__name" v-else key="2">NOMBRE COMPLETO</div>
								</transition>
							</label>
							<div class="card-item__date" ref="rCardDate">
								<label for="cardMonth" class="card-item__dateTitle">Expira</label>
								<label for="cardMonth" class="card-item__dateItem">
									<transition name="slide-fade-up">
										<span v-if="cardMonth" v-bind:key="cardMonth">{{ cardMonth }}</span>
										<span v-else key="2">MM</span>
									</transition>
								</label>
								/
								<label for="cardYear" class="card-item__dateItem">
									<transition name="slide-fade-up">
										<span v-if="cardYear" v-bind:key="cardYear">{{ String(cardYear).slice(2, 4) }}</span>
										<span v-else key="2">YY</span>
									</transition>
								</label>
							</div>
						</div>
					</div>
				</div>
				<div class="card-item__side -back">
					<div class="card-item__cover">
						<img v-bind:src="'cards/' + currentCardBackground + '.jpeg'" class="card-item__bg" />
					</div>
					<div class="card-item__band"></div>
					<div class="card-item__cvv">
						<div class="card-item__cvvTitle">CVV</div>
						<div class="card-item__cvvBand">
							<span v-for="(n, $index) in cardCvv" :key="$index">
								{{ n }}
							</span>
						</div>
						<div class="card-item__type">
							<img v-bind:src="'cards/' + getCardType + '.png'" v-if="getCardType" class="card-item__typeImg" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="card-form__inner">
			<div class="card-input">
				<label for="cardNumber" class="card-input__label">Número de tarjeta</label>
				<input type="text" id="cardNumber" :maxlength="cardNumberMaxLength" :masked="true" class="card-input__input" v-mask="generateCardNumberMask" v-model="cardNumber" v-on:focus="focusInput" v-on:blur="blurInput" data-ref="rCardNumber" autocomplete="off" />
			</div>
			<div class="card-input">
				<label for="cardName" class="card-input__label">Tarjetahabiente</label>
				<input type="text" id="cardName" class="card-input__input" v-model="cardName" v-on:focus="focusInput" v-on:blur="blurInput" data-ref="rCardName" autocomplete="off" />
			</div>
			<div class="card-input">
				<label for="cardAlias" class="card-input__label">Alias de tarjeta</label>
				<input type="text" id="cardAlias" class="card-input__input" v-model="cardAlias" v-bind:class="{ 'disabled-field': isEditMode === true }" :disabled="isEditMode" v-on:focus="focusInput" v-on:blur="blurInput" data-ref="rCardAlias" autocomplete="off" />
			</div>
			<div class="card-form__row">
				<div class="card-form__col">
					<div class="card-form__group">
						<label for="cardMonth" class="card-input__label">Fecha de expiración</label>
						<select class="card-input__input -select" id="cardMonth" v-model="cardMonth" v-on:focus="focusInput" v-on:blur="blurInput" data-ref="rCardDate">
							<option value="" disabled selected>Mes</option>
							<option v-bind:value="n < 10 ? '0' + n : n" v-for="n in 12" v-bind:disabled="n < minCardMonth" v-bind:key="n">
								{{ n < 10 ? "0" + n : n }}
							</option>
						</select>
						<select class="card-input__input -select" id="cardYear" v-model="cardYear" v-on:focus="focusInput" v-on:blur="blurInput" data-ref="rCardDate">
							<option value="" disabled selected>Año</option>
							<option v-bind:value="$index + minCardYear" v-for="(n, $index) in 12" v-bind:key="n">
								{{ $index + minCardYear }}
							</option>
						</select>
					</div>
				</div>
				<div class="card-form__col -cvv">
					<div class="card-input">
						<label for="cardCvv" class="card-input__label">CVV</label>
						<input type="text" class="card-input__input" id="cardCvv" :v-mask="cvvMask" :maxlength="cvvNumberMaxLength" v-model="cardCvv" v-on:focus="flipCard(true)" v-on:blur="flipCard(false)" autocomplete="off" />
					</div>
				</div>
			</div>

			<div class="card-form_button__container relative" style="height: 90px">
				<vue-recaptcha-enterprise :sitekey="siteKey" @onCaptchaSuccess="onCaptchaSuccess" @onCaptchaExpired="onCaptchaExpired" />
			</div>

			<div class="card-form_button__container relative">
				<button class="card-form__button card-form__button-cancel float-left" @click="goRoot">Cancelar</button>
				<button
					class="card-form__button float-right"
					v-bind:class="{
						'card-form__button-disabled': canSubmit === false,
					}"
					:disabled="!canSubmit"
					@click="saveCard"
				>
					Guardar
				</button>
			</div>
		</div>
	</div>
</template>

<script>
	import { useStore } from "vuex";
	import { format_card, luhn_check } from "../utils/commons";
	import { reactive, onMounted, computed, watch, ref, toRefs, nextTick } from "vue";
	import { useRouter, useRoute } from "vue-router";
	import { createToast } from "mosha-vue-toastify";
	import VueRecaptchaEnterprise from "@/components/VueRecaptchaEnterprise.vue";

	export default {
		name: "CardView",
		components: { VueRecaptchaEnterprise },
		setup() {
			const store = useStore();
			const router = useRouter();
			const route = useRoute();

			const data = reactive({
				currentCardBackground: 13,
				cardName: "",
				cardNumber: "",
				cardMonth: "",
				cardYear: "",
				cardCvv: "",
				cardAlias: "",
				minCardYear: new Date().getFullYear(),
				cardNumberMaxLength: 16,
				cvvNumberMaxLength: 3,
				cvvMask: "###",
				amexCardMask: "#### ###### #####",
				otherCardMask: "#### #### #### ####",
				dinnersCardMask: "#### ###### ####",
				cardNumberTemp: "",
				cardNumbTemp2: "",
				isCardFlipped: false,
				focusElementStyle: null,
				isInputFocused: false,
				isEditMode: false,
				jid: "",
				alias: "",
				generateCardNumberMask: "#### #### #### ####",
				paymentId: "",
				redirect: "",
			});
			const refs = {
				rCardDate: ref(null),
				rCardName: ref(null),
				rCardNumber: ref(null),
				rCardAlias: ref(null),
				rFocusElement: ref(null),
			};

			onMounted(async () => {
				data.cardNumberTemp = data.otherCardMask;
				document.getElementById("cardNumber").focus();

				data.isEditMode = route.params.isEditMode === "true";
				data.paymentId = route.params.paymentId;
				data.redirect = route.params.redirect;

				if (data.isEditMode === true) {
					data.jid = route.params.jid;
					data.alias = route.params.alias;

					try {
						store.commit("root/showLoading");
						await store.dispatch("card/get_card", {
							jid: data.jid,
							alias: data.alias,
						});
						store.commit("root/hideLoading");
					} catch (err) {
						store.commit("root/hideLoading");
						createToast(
							{ title: "Error", description: err.message },
							{
								type: "danger",
								position: "top-center",
								showIcon: true,
							}
						);
					}
				} else {
					data.jid = route.params.jid;
				}
			});

			const currentCard = computed(() => store.state.card.currentCard);
			const getCardType = computed(() => {
				let number = data.cardNumber.replace(/\s+/g, "");

				let re = new RegExp("^4");
				if (number.match(re) != null) return updateMask("visa");

				re = new RegExp("^5[1-5]");
				if (number.match(re) != null) return updateMask("mastercard");

				re = new RegExp("^(34|37)");
				if (number.match(re) != null) return updateMask("american_express");

				re = new RegExp("^3(?:0([0-5]|9)|[689]\\d?)\\d{0,11}");
				if (number.match(re) != null) return updateMask("diners_club");

				re = new RegExp("^6011");
				if (number.match(re) != null) return updateMask("discover");

				re = new RegExp("^35(2[89]|[3-8])");
				if (number.match(re) != null) return updateMask("jcb");

				re = new RegExp("^62");
				if (number.match(re) != null) return updateMask("china_union_pay");

				return "visa"; // default type
			});
			const minCardMonth = computed(() => {
				if (data.cardYear === data.minCardYear) return new Date().getMonth() + 1;
				return 1;
			});
			const canSubmit = computed(() => {
				return data.cardName !== "" && data.cardNumber !== "" && data.cardAlias !== "" && data.cardYear !== "" && data.cardMonth !== "" && data.cardCvv !== "" && isCaptchaSolved.value === true;
			});
			const updateMask = (processor) => {
				if (processor === "american_express") {
					data.cvvMask = "####";
					data.cvvNumberMaxLength = 4;
					data.cardNumberMaxLength = 17;
					data.generateCardNumberMask = data.amexCardMask;
				} else if (processor === "diners_club") {
					data.cardNumberMaxLength = 16;
					data.cvvMask = "###";
					if (data.cvvNumberMaxLength === 4) data.cardCvv = data.cardCvv.slice(0, -1);
					data.cvvNumberMaxLength = 3;
					data.generateCardNumberMask = data.dinnersCardMask;
				} else {
					data.cardNumberMaxLength = 19;
					data.cvvMask = "###";
					if (data.cvvNumberMaxLength === 4) data.cardCvv = data.cardCvv.slice(0, -1);
					data.cvvNumberMaxLength = 3;
					data.generateCardNumberMask = data.otherCardMask;
				}
				nextTick();

				return processor;
			};

			watch(
				() => data.cardYear,
				(newCardYear, prevCardYear) => {
					if (data.cardMonth < data.minCardMonth) {
						data.cardMonth = "";
					}
				}
			);

			watch(
				() => store.state.card.currentCard,
				(newCurrentCard, oldCurrentCard) => {
					data.cardName = newCurrentCard.holder_name;
					data.cardNumber = format_card(newCurrentCard.card_number);
					data.cardMonth = newCurrentCard.expiration_month;
					data.cardYear = parseInt("20" + newCurrentCard.expiration_year);
					data.cardCvv = newCurrentCard.cvv;
					data.cardAlias = newCurrentCard.alias;
				}
			);

			const goRoot = () => {
				router.push({
					name: "CardList",
					query: { paymentId: data.paymentId, redirect: data.redirect },
				});
			};

			const saveCard = async () => {
				try {
					if (luhn_check(data.cardNumber.replace(/\s+/g, "")) === false)
						createToast(
							{ title: "Error", description: "Tarjeta invalida" },
							{
								type: "danger",
								position: "top-center",
								showIcon: true,
							}
						);
					else {
						const payload = {
							alias: data.cardAlias.trim(),
							card_number: data.cardNumber.replace(/\s+/g, ""),
							holder_name: data.cardName.trim(),
							processor: getCardType,
							cvv: data.cardCvv,
							bank: "Generic",
							expiration_month: typeof data.cardMonth === "number" ? data.cardMonth.toString() : data.cardMonth,
							expiration_year: new String(data.cardYear).substring(2, 4),
							jid: data.jid,
						};

						store.commit("root/showLoading");
						if (data.isEditMode === false) await store.dispatch("card/post_card", payload);
						else await store.dispatch("card/put_card", payload);

						// store.commit("root/hideLoading");
						createToast(
							{
								title: "Exito",
								description: data.isEditMode === false ? "Tarjeta guardada con exito" : "Tarjeta actualizada con exito",
							},
							{
								type: "success",
								position: "top-center",
								showCloseButton: false,
								showIcon: true,
								timeout: 2500,
								onClose: () => {
									data.cardName = "";
									data.cardNumber = "";
									data.cardMonth = "";
									data.cardYear = "";
									data.cardCvv = "";
									data.cardAlias = "";
								},
							}
						);

						goRoot();
					}
				} catch (err) {
					store.commit("root/hideLoading");
					createToast(
						{ title: "Error", description: err.message },
						{
							type: "danger",
							position: "top-center",
							showIcon: true,
						}
					);
				}
			};

			const flipCard = (status) => {
				data.isCardFlipped = status;
			};

			const focusInput = (e) => {
				data.isInputFocused = true;
				let targetRef = e.target.dataset.ref;
				let target = refs[targetRef].value;
				data.focusElementStyle = {
					width: `${target.offsetWidth}px`,
					height: `${target.offsetHeight}px`,
					transform: `translateX(${target.offsetLeft}px) translateY(${target.offsetTop}px)`,
				};
			};

			const blurImput = () => {
				setTimeout(() => {
					if (!data.isInputFocused) {
						data.focusElementStyle = null;
					}
				}, 300);
				data.isInputFocused = false;
			};

			const isCaptchaSolved = ref(false);
			const onCaptchaExpired = () => {
				isCaptchaSolved.value = false;
			};
			const onCaptchaSuccess = (token) => {
				isCaptchaSolved.value = true;
			};
			const onCaptchaFail = (result) => {
				console.log("captcha failed");
				console.log(result);
				isCaptchaSolved.value = false;
			};

			return {
				...toRefs(data),
				...refs,
				currentCard,
				getCardType,
				minCardMonth,
				canSubmit,
				goRoot,
				saveCard,
				flipCard,
				focusInput,
				blurImput,
				siteKey: process.env.VUE_APP_RECAPTCHA_KEY,
				onCaptchaExpired,
				onCaptchaSuccess,
				onCaptchaFail,
			};
		},
	};
</script>

<style lang="scss">
	@import "../css/card-component.css";
	.disabled-field {
		background-color: rgb(182, 182, 182);
		color: white;
		cursor: not-allowed;
	}
</style>
